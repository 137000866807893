<div #container class="relative h-[75px] border-b border-gray-200">
	<button
		(click)="onPrev()"
		class="absolute top-0 left-0 hidden h-full pl-4 bg-white sm:inline"
	>
		<uc-arrow-left-svg customStyles="w-5 h-5" />
	</button>

	<div
		#content
		class="inline-block max-w-full px-2 py-4 space-x-2 overflow-x-auto sm:px-12 whitespace-nowrap scroll-smooth overflow-no-scrollbar"
	>
		@for (degree of degreeLevels(); track $index) {
			<button
				(click)="onSelectDegreeLevel(degree)"
				[attr.data-selected]="selectedDegreeLevel().label === degree.label"
				class="w-auto p-2 text-gray-900 border rounded-full focus:outline-none"
				[ngClass]="{
					'bg-uc-blue-900 text-white':
						selectedDegreeLevel().label === degree.label,
				}"
			>
				{{ degree.label }}
			</button>
		}
	</div>
	<button
		(click)="onNext()"
		class="absolute h-full pr-4 -right-0.5 top-0 bg-white hidden sm:inline"
	>
		<uc-arrow-right-svg customStyles="w-5 h-5" />
	</button>
</div>
