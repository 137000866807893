<div
	[ngClass]="{
		'border-red-500 focus-within:ring-red-600 focus-within:border-red-600':
			formSubmitted && control.errors,
		'border-gray-300 focus-within:ring-gray-400': !formSubmitted && !control.errors,
	}"
	class="relative col-span-1 px-3 py-2 border rounded-md shadow-sm input-focus-within"
>
	<label
		[attr.for]="controlName"
		class="absolute top-0 inline-block px-1 -mt-px text-xs font-medium text-gray-700 bg-white left-2"
		[ngClass]="{ 'show-label': value, label: !value }"
	>
		Postcode*
	</label>

	<div class="relative">
		<input
			#input
			[value]="value"
			(blur)="formatPostcode()"
			[attr.id]="controlName"
			[attr.name]="controlName"
			[placeholder]="placeholderText"
			type="text"
			autocomplete="postal-code"
			(input)="updateValue(input.value)"
			class="block w-full p-0 text-gray-900 border-0 outline-none focus:outline-none focus:ring-0"
		/>
		@if (!hideResidencySwitch) {
			<button
				(click)="clickNotUk()"
				type="button"
				class="absolute top-0 bottom-0 right-0 flex flex-col justify-center text-sm cursor-pointer blue-link"
			>
				Not UK?
			</button>
		}
	</div>
</div>

@if (formSubmitted) {
	<div class="w-full">
		<!-- for required errors -->
		<uc-form-error
			fieldError="Please enter your postcode."
			[formError]="formSubmitted && control.hasError('required')"
		>
		</uc-form-error>
		<!-- invalid postcode -->
		<uc-form-error
			fieldError="Incorrect postcode format."
			[formError]="
				formSubmitted &&
				control.hasError('postcodeInvalid') &&
				!control.hasError('required')
			"
		>
		</uc-form-error>
	</div>
}
