@if (open()) {
	<div
		class="fixed mt-3 overflow-hidden z-[999] w-screen h-screen bg-white border md:rounded-md md:absolute md:h-auto"
		[style]="{
			top: isMobile || !position() ? 'auto' : position()?.top + 'px',
			left: isMobile || !position() ? 'auto' : position()?.left + 'px',
		}"
		[ngClass]="customStyles()"
	>
		@if (showDegreeLevelOptions() || isMobile) {
			<uc-degree-level-selector
				[selectedDegreeLevel]="selectedDegreeLevel()"
				[degreeLevels]="degreeLevels()"
				(selectDegreeLevel)="onSelectDegreeLevel($event)"
			/>
		}
		@if (isLoading()) {
			<div class="flex items-center justify-center p-6">
				<uc-loading-spinner-svg customStyles="!w-8 !h-8" />
			</div>
		} @else if (suggestions()?.length) {
			<uc-course-suggestions
				[suggestions]="suggestions()!"
				[parentInput]="parentInput()"
				[focusedIndex]="focusedIndex()"
				[maxSuggestions]="maxSuggestions"
				[selectedDegreeLevel]="selectedDegreeLevel()"
				(selectSuggestion)="onSelectSuggestion($event)"
				(resetFocusedIndex)="focusedIndex.set(-1)"
			/>
		} @else {
			<p class="p-3 text-lg text-center text-gray-900">No courses found</p>
		}
	</div>
}
