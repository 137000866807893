import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ArrowRightSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-header-search-bar',
	standalone: true,
	templateUrl: './header-search-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SearchSvgComponent, ArrowRightSvgComponent, CommonModule],
})
export class HeaderSearchBarComponent {
	placeholder = input('Search');
	searchTerm = input<string | null>('');
	buttonStyles = input<string>('bg-white hover:bg-gray-100');

	search = output<string>();

	onSearch(keyword: string) {
		this.search.emit(keyword);
	}
}
