<div class="w-full">
	<label for="search" class="sr-only">Search</label>
	<div class="relative">
		<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
			<uc-search-svg customStyles="text-gray-400" />
		</div>
		<div class="flex w-full">
			<input
				class="w-full px-4 py-2 pl-12 text-base text-gray-600 placeholder-gray-400 border border-r-0 border-gray-300 rounded-lg filter-overlay-search focus:outline-none sm:rounded-l-xl sm:rounded-r-none"
				type="search"
				#searchBox
				[value]="searchTerm() ?? ''"
				(keyup.enter)="onSearch(searchBox.value)"
				id="inputSearchUni"
				[placeholder]="placeholder()"
				aria-label="Search"
				autocomplete="off"
			/>

			<!-- search button for desktop -->
			<button
				(click)="onSearch(searchBox.value)"
				class="items-center hidden border-l-2 border-l-uc-blue-800 gap-4 text-uc-blue-900 !px-8 !py-4 !text-xl !rounded-l-none rounded-r-xl font-semibold sm:flex"
				[ngClass]="buttonStyles()"
			>
				<span>Search</span>
				<uc-arrow-right-svg />
			</button>
		</div>
	</div>

	<!-- search button for mobile -->
	<button
		(click)="onSearch(searchBox.value)"
		class="flex items-center justify-center w-full gap-4 px-8 py-2 mt-4 text-xl font-semibold rounded-md text-uc-blue-900 sm:hidden"
		[ngClass]="buttonStyles()"
	>
		<span>Search</span>
		<uc-arrow-right-svg customStyles="text-inherit" />
	</button>
</div>
