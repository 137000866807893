@if (countryCodes().length > 0) {
	<div class="relative h-full">
		<label
			[attr.for]="idAttr"
			class="absolute top-0 inline-block px-1 -mt-px text-xs font-medium text-gray-700 bg-white left-2 show-label whitespace-nowrap"
		>
			Country Code*
		</label>

		<select
			(change)="updateValue($event)"
			[attr.id]="idAttr"
			autocomplete="tel-country-code"
			class="h-full p-2 border-l-0 border-r !border-gray-200 rounded-l-lg rounded-r-none border-y-0 w-min sm:text-sm focus:outline-offset-0 focus:outline-2 focus:outline-gray-400 focus:ring-0"
		>
			<option value="" selected disabled></option>
			@for (code of countryCodes(); track $index) {
				<option [value]="code.dial_code" [selected]="code.dial_code === value">
					{{ code.emoji }} {{ code.dial_code }}
				</option>
			}
		</select>
	</div>
}
