import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	OnDestroy,
	Inject,
	Renderer2,
	DestroyRef,
	output,
	input,
	signal,
	computed,
} from '@angular/core';
import {
	Subscription,
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	of,
	startWith,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { CourseSearchAutosuggestApiService } from '../data-access/course-search-autosuggest-api.service';
import { CourseSuggestion } from '../models/course-search-autosuggest.models';
import { CommonModule, DOCUMENT } from '@angular/common';

import { Router } from '@angular/router';
import { popularCourses } from '../static/course-search-autosuggest.properties';
import { CourseSuggestionsComponent } from '../components/course-suggestions/course-suggestions.component';
import {
	DegreeLevelOption,
	DegreeLevelPayload,
	ScreenWidth,
} from '@uc/web/shared/data-models';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DEGREE_LEVEL_TYPE_MAPPER, DegreeLevelService } from '@uc/web/shared/data-access';
import { DegreeLevelSelectorComponent } from '../components/degree-level-selector/degree-level-selector.component';
import { LoadingSpinnerSvgComponent } from '@uc/shared/svg';
import { CourseSearchAutosuggestService } from '../utils/course-search-autosuggest.service';
import { HECosSubjectValidatorService } from '@uc/web/core';

@Component({
	selector: 'uc-course-search-autosuggest',
	templateUrl: './course-search-autosuggest.component.html',
	standalone: true,
	imports: [
		CommonModule,
		CourseSuggestionsComponent,
		DegreeLevelSelectorComponent,
		LoadingSpinnerSvgComponent,
	],
	providers: [CourseSearchAutosuggestApiService],
})
export class CourseSearchAutosuggestComponent implements OnInit, OnDestroy {
	parentInput = input.required<HTMLInputElement>();
	position = input<{ top: number; left: number }>();
	resetSearchTerm = input<boolean>();
	navigate = input<boolean>(false);
	showDegreeLevelOptions = input(false);
	customStyles = input('');

	selectSuggestion = output<{ degree: string; searchTerm: string }>();
	autoSuggestOpen = output<boolean>();

	degreeLevels = computed(() => this._degreeLevelSrv.degreeLevels());
	selectedDegreeLevel = computed(() => this._degreeLevelSrv.selectedDegreeLevel());

	open = signal<boolean>(false);
	suggestions = signal<CourseSuggestion[] | null>(popularCourses);
	focusedIndex = signal(-1);
	isLoading = signal(false);

	isMobile!: boolean;
	maxSuggestions = 10;

	private _downArrowKeyPressSub!: Subscription;
	private _inputFocusSub!: Subscription;
	private _onEnterSub!: Subscription;
	private _breakpointSub = this._breakpointObserver
		.observe(`(max-width: ${ScreenWidth.SM - 1}px)`)
		.subscribe((result) => (this.isMobile = result.matches));

	// Closes the autosuggest dropdown when user clicks outside of it.
	@HostListener('document:click', ['$event'])
	clickout(event: MouseEvent) {
		if (!this._elementRef.nativeElement.contains(event.target)) {
			this.closeAutoSuggest();
		}
	}

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _elementRef: ElementRef,
		private _router: Router,
		private _renderer: Renderer2,
		private _destroyRef: DestroyRef,
		private _breakpointObserver: BreakpointObserver,
		private _autosuggestApiSrv: CourseSearchAutosuggestApiService,
		private _autosuggestSrv: CourseSearchAutosuggestService,
		private _degreeLevelSrv: DegreeLevelService,
		private _hecosSubjectValidatorSrv: HECosSubjectValidatorService,
	) {}

	ngOnInit() {
		if (this.isMobile) {
			this._handleMobileFocus();
		} else {
			this._onInputChange();
			this._onInputDownArrowKeyPress();
			this._onEnter();
		}
	}

	ngOnDestroy() {
		this._downArrowKeyPressSub?.unsubscribe();
		this._inputFocusSub?.unsubscribe();
		this._onEnterSub?.unsubscribe();
		this._breakpointSub?.unsubscribe();

		this._renderer.removeClass(this._document.body, 'overflow-hidden');
	}

	onSelectDegreeLevel(degreeLevel: DegreeLevelOption) {
		const searchTerm = this.parentInput().value;
		this._degreeLevelSrv.selectedDegreeLevel.set(degreeLevel);

		if (!searchTerm) return;
		this.isLoading.set(true);
		this._autosuggestApiSrv
			.getCourseSuggestions(searchTerm, this._getDegreeLevel())
			.pipe(
				take(1),
				debounceTime(300),
				tap((suggestions) => {
					this.suggestions.set(suggestions);
					this.isLoading.set(false);
				}),
			)
			.subscribe();
	}

	// Handles the input change event and returns the suggestions from the api
	private _onInputChange() {
		return fromEvent(this.parentInput(), 'input')
			.pipe(
				startWith({ target: { value: '' } }),
				debounceTime(300),
				takeUntilDestroyed(this._destroyRef),
				distinctUntilChanged(),
				map((elem) => (elem.target as HTMLInputElement).value),
				switchMap((term) => {
					if (term.length < 1) {
						if (this.isMobile) {
							this.maxSuggestions = 16;
							this.suggestions.set(popularCourses);
							return of(popularCourses);
						}

						this.suggestions.set([]);
						this.open.set(false);
						return of([]);
					}
					this.maxSuggestions = 10;

					return this._autosuggestApiSrv.getCourseSuggestions(
						term,
						this._getDegreeLevel(),
					);
				}),
				filter((suggestions: CourseSuggestion[]) => suggestions.length > 0),
				tap((suggestions) => {
					this.suggestions.set(suggestions);
					this.open.set(true);
					this.autoSuggestOpen.emit(true);
					this._autosuggestSrv.autoSuggestOpen.set(true);
				}),
			)
			.subscribe();
	}

	// Handles the down arrow key press event on the input field to move focus to
	// the autosuggest.
	private _onInputDownArrowKeyPress() {
		this._downArrowKeyPressSub = fromEvent<KeyboardEvent>(
			this.parentInput(),
			'keydown',
		)
			.pipe(
				takeUntilDestroyed(this._destroyRef),
				filter((event: KeyboardEvent) => event.key === 'ArrowDown'),
				tap((event) => {
					if (this.focusedIndex() === -1 && this.open()) {
						event.preventDefault();
						this.focusedIndex.set(0);
					}
				}),
			)
			.subscribe();
	}

	onSelectSuggestion(searchTerm: string) {
		this.navigateToPage(searchTerm);

		const autosuggest = {
			degree: this.selectedDegreeLevel().link,
			searchTerm,
		};

		this.selectSuggestion.emit(autosuggest);
		this.closeAutoSuggest();
	}

	async navigateToPage(searchTerm: string) {
		if (!searchTerm) return;

		this.parentInput().value = searchTerm.replace(/-/g, ' ');
		if (this.resetSearchTerm()) {
			this.parentInput().value = '';
		}

		if (this.navigate() === false) return;
		const subject = searchTerm.replace(/ /g, '-').toLowerCase();

		const isHECoSSubject =
			await this._hecosSubjectValidatorSrv.isHECoSSubject(subject);
		const degree = this.selectedDegreeLevel().link;

		const segments = isHECoSSubject
			? [...new Set(['/courses', 'degrees', degree, subject])].filter(Boolean)
			: ['/courses', 'search', degree].filter(Boolean);

		this._router.navigate([...segments], {
			queryParams: isHECoSSubject ? {} : { search: subject },
		});
	}

	closeAutoSuggest() {
		this.open.set(false);
		this.focusedIndex.set(-1);
		this.autoSuggestOpen.emit(false);
		this._autosuggestSrv.autoSuggestOpen.set(false);
		this._renderer.removeClass(this._document.body, 'overflow-hidden');
	}

	private _handleMobileFocus() {
		this._inputFocusSub = fromEvent<FocusEvent>(this.parentInput(), 'focus')
			.pipe(
				takeUntilDestroyed(this._destroyRef),
				tap((event: FocusEvent) => {
					if (event.type === 'focus' && this.isMobile) {
						this._renderer.addClass(this._document.body, 'overflow-hidden');
					}
					this._onInputChange();
					this._onInputDownArrowKeyPress();
				}),
			)
			.subscribe();
	}

	// Closes the autosuggest dropdown when user presses enter.
	private _onEnter() {
		this._onEnterSub = fromEvent(this.parentInput(), 'keyup')
			.pipe(
				takeUntilDestroyed(this._destroyRef),
				tap((e: Event) => {
					if ((e as KeyboardEvent).key === 'Enter') {
						const value = (e.target as HTMLInputElement).value;
						this.focusedIndex.set(-1);
						this.open.set(false);
						this.autoSuggestOpen.emit(false);
						this._autosuggestSrv.autoSuggestOpen.set(false);
						this.navigateToPage(value);
					}
				}),
			)
			.subscribe();
	}

	private _getDegreeLevel() {
		const degreeLevel = this.selectedDegreeLevel();

		return DEGREE_LEVEL_TYPE_MAPPER[
			degreeLevel.link as keyof typeof DEGREE_LEVEL_TYPE_MAPPER
		] as DegreeLevelPayload;
	}
}
